<template>
  <div
    class="survey-completed h-100 text-center d-flex align-center justify-center"
    style="font-size: 50px; font-weight: bold"
  >
    <template v-if="alreadyCompleted">
      {{ $t("survey.alreadyCompletedMessage") }}
    </template>

    <template v-else>
      {{ $t("survey.completedMessage") }}
    </template>
  </div>
</template>

<script>
export default {
  props: {
    alreadyCompleted: Boolean,
  },
};
</script>

<style></style>
